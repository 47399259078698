const MenuList = [
  {
    name: "바른미의 특별함",
    url: "why",
    subMenu: [
      "Why? 바른미",
      "바른미 의료진",
      "바른미 검사실",
      "바른미 둘러보기",
    ],
    subUrl: [
      "1", "2", "3", "4"
    ]
  },
  {
    name: "코성형",
    url: "noseSurgery",
    subMenu: [
      "비개방 내시경 기능코성형",
      "노즈필 코성형",
    ],
    subUrl: [
      "1", "2"
    ],
    subTitle: [
      "바른 기능, 아름다운 코를 위한 디지털 기능 코성형",
      "바른 기능, 아름다운 코를 위한 디지털 기능 코성형",
    ],
  },
  {
    name: "코수술센터",
    url: "nose",
    subMenu: [
      "내시경 비염•비중격 수술",
      "네비게이션 축농증 수술",
      "3D-CT 코뼈골절",
      "비개방 코 재수술",
      "만성 비염",
    ],
    subUrl: [
      "1", "2", "3", "4", "5"
    ],
    subTitle: [
      "바른 기능, 아름다운 코를 위한 디지털 기능 코성형",
      "바른 기능, 아름다운 코를 위한 디지털 기능 코성형",
      "바른 기능, 아름다운 코를 위한 디지털 기능 코성형",
      "바른 기능, 아름다운 코를 위한 디지털 기능 코성형",
      "바른 기능, 아름다운 코를 위한 디지털 기능 코성형",
    ],
  },
  {
    name: "목초음파센터",
    url: "neck",
    subMenu: [
      "갑상선 초음파",
      "경동맥 초음파",
      "경부 침샘 초음파",
      "역류성 후두질환",
    ],
    subUrl: [
      "1", "2", "3", "4"
    ],
    subTitle: [
      "바른 기능, 아름다운 목을 위한 갑상선 초음파 검사",
      "바른 기능, 아름다운 목을 위한 경동맥 초음파 검사",
      "바른 기능, 아름다운 목을 위한 경부•침샘 초음파 검사",
      "바른 기능, 아름다운 목을 위한 역류성 후두질환 검사",
    ],
  },
  {
    name: "귀어지럼증센터",
    url: "ear",
    subMenu: [
      "VNG 어지럼증 클리닉",
      "보청기",
      "이명",
      "중이염",
    ],
    subUrl: [
      "1", "2", "3", "4"
    ],
    subTitle: [
      "바른 기능, 아름다운 귀를 위한 VNG 어지럼증 클리닉",
      "바른 기능, 아름다운 귀를 위한 보청기 검사",
      "바른 기능, 아름다운 귀를 위한 이명 검사",
      "바른 기능, 아름다운 귀를 위한 중이염 검사",
    ],
  },
  {
    name: "SAFE 수술 검진센터",
    url: "safe",
    subMenu: [
      "수술 후 통증 관리",
      "종합검진 세부항목",
      "종합검진 진행과정",
    ],
    subUrl: [
      "1", "2", "3"
    ],
    subTitle: [
      "바른 기능, 아름다운 몸을 위한 수술 후 통증 케어",
      "바른 기능, 아름다운 몸을 위한 종합검진 세부항목",
      "바른 기능, 아름다운 몸을 위한 종합검진 진행과정",
    ],
  },
  {
    name: "전/후 사진",
    url: "images",
    subMenu: [
      "코성형", "비중격 비염", "축농증"
    ],
    subUrl: [
      "1", "2", "3"
    ],
    subTitle: [],
  },
  {
    name: "진료상담",
    url: "contact",
    subMenu: [
      "진료상담", "실비보험 안내", "비급여품목"
    ],
    subUrl: [
      "1", "2","3"
    ],
    subTitle: [],
  },
]

export { MenuList }